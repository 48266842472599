import firebase from 'firebase/app'
import 'firebase/firestore'
// import 'firebase/storage'
import 'firebase/auth'
import firebaseConfig from '@/firebaseConfig.js'

const firebaseApp = firebase.initializeApp(firebaseConfig)
var db = firebaseApp.firestore()
// var storage = firebaseApp.storage()
var auth = firebaseApp.auth()
// const settings = {timestampsInSnapshots: true}
// db.settings(settings)

// export default db
export default {
  // storage: storage,
  // db: db,
  db: db,
  auth: auth,
  firebase: firebase,
  EmailAuthProvider: firebase.auth.EmailAuthProvider
}
