<template>
  <div class="max-w-6xl mx-auto px-3 pb-20">

    <div class="max-w-3xl mx-auto pb-6 md:pb-8">
      <!--<p class="text-5xl text-white text-4xl lg:text-5xl font-bold leading-none sm:leading-tighter pb-12 sm:text-center">Find out just how easy running your show can be. Sign up today!</p>-->
      <p class="text-white text-4xl lg:text-5xl font-bold leading-none sm:leading-tighter pb-4 sm:text-center">Sign up to use Showman.</p>
      <p class="text-white text-lg lg:text-xl font-light leading-none sm:leading-tighter sm:text-center pb-2">
        After completing this form you will recieve an email with instructions on scheduling an initial set up call.
      </p>

    </div>
    <!--<div class="flex flex-col-reverse items-center justify-center md:flex-row pb-16">-->
      <!--<div class="md:w-1/2 md:mr-12 md:ml-4 max-w-lg pb-10 md:pb-0">
        <div class="w-full bg-gray-100 text-center p-6 rounded-t">
          <h3 class="text-gray-900 uppercase tracking-wide text-lg sm:text-xl text-center font-bold my-0">
            Special Launch Pricing
          </h3>
        </div>
        <div class="w-full bg-white p-4 rounded-b">
          <div class="text-center py-6 lg:py-4">
            <span class="line-through opacity-75 inline-flex items-center font-display text-xl md:text-2xl font-semibold text-gray-600 mr-3 sm:mr-4">
              <span class="text-xl">$</span><span>269</span>
            </span>
            <span class="inline-flex items-center font-display text-5xl md:text-6xl font-bold text-black mr-3 sm:mr-4">
              <span class="text-2xl md:text-3xl">$</span><span>199</span>
            </span>
            <span class="inline-flex items-center font-display text-xl md:text-2xl font-bold text-grey-dark mr-3 sm:mr-4">
              USD
            </span>
          </div>
          <ul class="list-reset px-2 sm:px-6 sm:text-sm">
            <li class="flex items-start mb-4">
              <span class="mr-4 mt-1"><svg class="h-5 w-5 block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><g fill="none" fill-rule="evenodd"><circle cx="10" cy="10" r="10" fill="#FBD38D"></circle><polyline stroke="rgb(246, 153, 63)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="6 10 8.667 12.667 14 7.333"></polyline></g></svg></span>
              <span>One event <strong>with up to 1000 individual entries.</strong></span>
            </li>
            <li class="flex items-start mb-4">
              <span class="mr-4 mt-1"><svg class="h-5 w-5 block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><g fill="none" fill-rule="evenodd"><circle cx="10" cy="10" r="10" fill="#FBD38D"></circle><polyline stroke="rgb(246, 153, 63)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="6 10 8.667 12.667 14 7.333"></polyline></g></svg></span>
              <span>Set up and configure an <strong>unlimited number of individual shows </strong> for the event.</span>
            </li>
            <li class="flex items-start mb-4">
              <span class="mr-4 mt-1"><svg class="h-5 w-5 block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><g fill="none" fill-rule="evenodd"><circle cx="10" cy="10" r="10" fill="#FBD38D"></circle><polyline stroke="rgb(246, 153, 63)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="6 10 8.667 12.667 14 7.333"></polyline></g></svg></span>
              <span><strong>Fully customizable</strong> show entry settings. Includes support for <strong>all variety of livestock</strong> and show types.</span>
            </li>
            <li class="flex items-start mb-4">
              <span class="mr-4 mt-1"><svg class="h-5 w-5 block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><g fill="none" fill-rule="evenodd"><circle cx="10" cy="10" r="10" fill="#FBD38D"></circle><polyline stroke="rgb(246, 153, 63)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="6 10 8.667 12.667 14 7.333"></polyline></g></svg></span>
              <span>A <strong>complete set</strong> of show day tools that take you from check in to recording the final show results.</span>
            </li>
            <div class="hr"></div>
            <p class="text-gray-900 uppercase tracking-wide sm:text-lg text-center font-bold pb-4">
              Launch Exclusives
            </p>
            <li class="flex items-start mb-4">
              <span class="mr-4 mt-1"><svg class="h-5 w-5 block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><g fill="none" fill-rule="evenodd"><circle cx="10" cy="10" r="10" fill="#FBD38D"></circle><polyline stroke="rgb(246, 153, 63)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="6 10 8.667 12.667 14 7.333"></polyline></g></svg></span>
              <span><strong>Personalized one-on-one onboarding.</strong> Ensure that your shows settings and presentation are exactly how you want them.</span>
            </li>
            <li class="flex items-start mb-4">
              <span class="mr-4 mt-1"><svg class="h-5 w-5 block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><g fill="none" fill-rule="evenodd"><circle cx="10" cy="10" r="10" fill="#FBD38D"></circle><polyline stroke="rgb(246, 153, 63)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="6 10 8.667 12.667 14 7.333"></polyline></g></svg></span>
              <span><strong>Guaranteed Pricing.</strong> All new features developed between now and your show are included at no additional cost.</span>
            </li>
            <li class="flex items-start mb-4">
              <span class="mr-4 mt-1"><svg class="h-5 w-5 block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><g fill="none" fill-rule="evenodd"><circle cx="10" cy="10" r="10" fill="#FBD38D"></circle><polyline stroke="rgb(246, 153, 63)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="6 10 8.667 12.667 14 7.333"></polyline></g></svg></span>
              <span><strong>Premium Support</strong> including phone and weekend support.</span>
            </li>
          </ul>
        </div>
      </div>
    </div>-->
    <form v-show="formStatus === 'none'" id="signup" class="w-full py-10 px-5 rounded-lg bg-gray-800 mb-10">
      <div class="flex flex-col justify-center md:flex-row">
        <div class="md:w-1/2 md:mr-4">
          <p class="text-3xl font-thin pb-4 text-white">My Information.</p>
          <div class="w-full md:flex md:items-center md:justify-center mb-4">
            <div class="w-full md:w-1/2 md:pr-2">
              <label class="block text-gray-300 text-sm font-bold mb-2">
                First Name
              </label>
              <input v-model="signUpObject.first_name" class="appearance-none bg-gray-200 w-full text-gray-800 mr-3 py-2 px-2 leading-tight focus:outline-none rounded" :class="{'bg-red-400': missingField == 'first name'}" id="inline-username" type="text" placeholder="">
            </div>
            <div class="w-full md:w-1/2">
              <label class="block text-gray-300 text-sm font-bold mb-2">
                Last Name
              </label>
              <input v-model="signUpObject.last_name" class="appearance-none bg-gray-200 w-full text-gray-800 mr-3 py-2 px-2 leading-tight focus:outline-none rounded" :class="{'bg-red-400': missingField == 'last name'}" id="inline-username" type="text" placeholder="">
            </div>
          </div>
          <div class="w-full md:flex md:items-center md:justify-center mb-4">
            <div class="w-full">
              <label class="block text-gray-300 text-sm font-bold mb-2">
                What is your email address?
              </label>
              <input v-model="signUpObject.email" class="appearance-none bg-gray-200 w-full text-gray-800 mr-3 py-2 px-2 leading-tight focus:outline-none rounded" :class="{'bg-red-400': missingField == 'email'}" id="inline-username" type="email" placeholder="">
            </div>
          </div>
          <!--<div class="flex">
            <div class="w-full md:flex md:items-center md:justify-center mb-4 pr-4">
              <div class="w-full">
                <label class="block text-gray-300 text-sm font-bold mb-2" for="username">
                  First Name
                </label>
                <input v-model="first_name" class="appearance-none bg-gray-200 w-full text-gray-800 mr-3 py-2 px-2 leading-tight focus:outline-none rounded" :class="{'bg-red-400': missingField == 'name'}" id="inline-username" type="text" placeholder="First Name">
              </div>
            </div>
            <div class="w-full md:flex md:items-center md:justify-center mb-4">
              <div class="w-full">
                <label class="block text-gray-300 text-sm font-bold mb-2" for="username">
                  Last Name
                </label>
                <input v-model="last_name" class="appearance-none bg-gray-200 w-full text-gray-800 mr-3 py-2 px-2 leading-tight focus:outline-none rounded" :class="{'bg-red-400': missingField == 'name'}" id="inline-username" type="text" placeholder="First Name">
              </div>
            </div>
          </div>-->
          <div class="w-full md:flex md:items-center md:justify-center mb-4">
            <div class="w-full">
              <label class="block text-gray-300 text-sm font-bold mb-2">
                What is your phone number?
              </label>
              <input v-model="signUpObject.phone_number" class="appearance-none bg-gray-200 w-full text-gray-800 mr-3 py-2 px-2 leading-tight focus:outline-none rounded" :class="{'bg-red-400': missingField == 'phone number'}" id="inline-username" type="tel" placeholder="">
            </div>
          </div>
          <div class="w-full md:flex md:items-center md:justify-center mb-4 ">
            <div class="w-full">
              <label class="block text-gray-300 text-sm font-bold mb-2">
                Which best describes your interest level?
              </label>
              <select v-model="signUpObject.interest" class="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline" :class="{'bg-red-400': missingField == 'interest'}">
                <option disabled selected>Select Your Interest Level</option>
                <option :value="l.value" v-for="(l, i) in interestLevels" :key="i">
                  {{l.label}}
                </option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
              </div>
            </div>
          </div>
          <div class="w-full md:flex md:items-center md:justify-center mb-4 ">
            <div class="w-full">
              <label class="block text-gray-300 text-sm font-bold mb-2">
                How did you hear about Showman?
              </label>
              <select v-model="signUpObject.how_did_you_hear" class="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline" :class="{'bg-red-400': missingField == 'how_did_you_hear'}">
                <option disabled selected>Select Your Interest Level</option>
                <option :value="l.value" v-for="(l, i) in refferalSources" :key="i">
                  {{l.label}}
                </option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
              </div>
            </div>
          </div>
          <div class="w-full md:flex md:items-center md:justify-center mb-4">
            <div class="w-full">
              <label class="block text-gray-300 text-sm font-bold mb-2">
                Who referred you? Or any other informaiton about how you heard about Showman.
              </label>
              <input v-model="signUpObject.referral_details" class="appearance-none bg-gray-200 w-full text-gray-800 mr-3 py-2 px-2 leading-tight focus:outline-none rounded" :class="{'bg-red-400': missingField == 'referral_details'}" id="inline-username" type="text" placeholder="">
            </div>
          </div>
          <!--<div class="w-full md:flex md:items-center md:justify-center mb-4">
            <div class="w-full">
              <label class="block text-gray-300 text-sm font-bold mb-2">
                What is your role in regards to this show?
              </label>
              <input v-model="phone_number" class="appearance-none bg-gray-200 w-full text-gray-800 mr-3 py-2 px-2 leading-tight focus:outline-none rounded" :class="{'bg-red-400': missingField == 'name'}" id="inline-username" type="text" placeholder="First Name">
            </div>
          </div>-->
          <!--<div class="hr"></div>-->
          <!--<p class="text-3xl font-thin pb-4 text-white">Your Show's Info</p>-->
        </div>
        <div class="md:w-1/2 md:ml-4">
          <p class="text-3xl font-thin pb-4 text-white">My Show's Information.</p>
          <div class="md:flex md:items-center md:justify-center mb-4">
            <div class="w-full">
              <label class="block text-gray-300 text-sm font-bold mb-2" for="username">
                What is the name of your show?
              </label>
              <input v-model="signUpObject.show_name" class="appearance-none bg-gray-200 w-full text-gray-800 mr-3 py-2 px-2 leading-tight focus:outline-none rounded" :class="{'bg-red-400': missingField == 'show name'}" id="inline-username" type="text" placeholder="">
            </div>
          </div>
          <div class="w-full md:flex md:items-center md:justify-center mb-4">
            <div class="w-full">
              <label class="block text-gray-300 text-sm font-bold mb-2" for="username">
                When does your show start?
              </label>
              <input v-model="signUpObject.show_start_date" class="appearance-none bg-gray-200 w-full text-gray-800 mr-3 py-2 px-2 leading-tight focus:outline-none rounded" :class="{'bg-red-400': missingField == 'show start date'}" id="inline-username" type="date" placeholder="">
            </div>
          </div>
          <div class="w-full md:flex md:items-center md:justify-center mb-4">
            <div class="w-full">
              <label class="block text-gray-300 text-sm font-bold mb-2" for="username">
                When would you like to open up online entries by? (This can be updated later)
              </label>
              <input v-model="signUpObject.online_entires_open" class="appearance-none bg-gray-200 w-full text-gray-800 mr-3 py-2 px-2 leading-tight focus:outline-none rounded" :class="{'bg-red-400': missingField == 'entry open date'}" id="inline-username" type="date" placeholder="">
            </div>
          </div>
          <div class="md:flex md:items-center md:justify-center mb-2">
            <div class="w-full">
              <label class="block text-gray-300 text-sm font-bold mb-2" for="username">
                Which species will be shown?
              </label>
              <multiselect class="my-2" v-model="signUpObject.species" :options="speciesList" :multiple="true" :close-on-select="false" :clear-on-select="false"  placeholder="Select a species" :show-labels="false"></multiselect>
            </div>
          </div>
          <div class="md:flex md:items-center md:justify-center mb-4">
            <div class="w-full">
              <label class="block text-gray-300 text-sm font-bold mb-2" for="username">
                What state is your show in?
              </label>
              <input v-model="signUpObject.show_state" class="appearance-none bg-gray-200 w-full text-gray-800 mr-3 py-2 px-2 leading-tight focus:outline-none rounded" :class="{'bg-red-400': missingField == 'show_state'}" id="inline-username" type="text" placeholder="">
            </div>
          </div>
        </div>
      </div>
      <div class="md:max-w-xl md:mx-auto">
        <div v-if="missingField" class="text-red-700 mb-2 text-center">Please fill out the <strong>{{missingField}}</strong> field before submitting.</div>
        <div v-else-if="missingTOS" class="text-red-700 mb-2 text-center">You must agree to the Terms of Service before continuing.</div>

        <div class="md:flex mb-3">
          <label class="block text-gray-500 font-bold">
            <input v-model="signUpObject.tos" class="mr-2 leading-tight" type="checkbox">
            <span class="text-sm">
              I accept Showman's <a class="text-orange-500" target="_blank" href="/terms_of_service.html">Terms of Service</a>
            </span>
          </label>
        </div>
        <div class="md:flex md:items-center md:justify-center mt-6 pb-2">
          <button @click="signUpClicked" class="text-xl tracking-wide shadow w-full bg-orange-500 hover:bg-orange-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" type="button">
            Sign up
          </button>
        </div>
        <p class="text-gray-300 leading-snug sm:tracking-snug text-center text-lg">Are you an exhibitor? <a class="link" href="/shows"> Click here to enter your show.</a></p>
      </div>
    </form>
    <div v-show="formStatus === 'loading'" class="w-64 bg-white rounded-lg mx-auto p-6 mb-12">
      <vue-simple-spinner class="my-6" size="large" :speed="1.1" :line-size="7" message="Submitting Show Info" line-fg-color="orange"/>
    </div>
    <div v-show="formStatus === 'success'" class="mx-auto max-w-4xl mb-10">
      <div class="flex flex-col bg-green-200 rounded p-6">
        <p class="text-2xl text-black"><strong>Success!</strong></p>
        <p class='main-copy'>Thank you for signing up your show to use Showman! Check your inbox for an email with instructions on getting started. If you don't receive it or if you have any questions you can send an email to <a href="mailto:support@showman.app" class="link">support@showman.app</a>.</p>
      </div>
    </div>
    <div v-show="formStatus === 'error'" class="mx-auto max-w-4xl">
      <div class="bg-red-100 border-t-4 border-red-500 rounded-b text-red-900 px-4 py-3 my-6 text-left" role="alert">
        <p class="font-bold">Oh Snap!</p>
        <p class="pb-3">There was a problem submitting your show information. See more details below:</p>
        <p class='pb-3'>{{errorMessage}}</p>
        <a class='btn btn-gray inline-block' href="javascript:location.reload();">Click here to try again</a>
      </div>
    </div>
    <div class="text-center mt-4 max-w-2xl mx-auto">
      <div style="padding:61.22% 0 0 0;position:relative;">
        <iframe src="https://player.vimeo.com/video/605171753?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=b9be0c1b9a" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Showman _ Admin Overview.mp4"></iframe>
      </div>
      <p class="mt-2 text-white text-2xl lg:text-3xl font-bold leading-none sm:leading-tighter pb-4 sm:text-center">Quick 5 Minute Overview Video</p>
    </div>
    <p class="text-5xl text-white text-4xl lg:text-5xl font-bold leading-none sm:leading-tighter pb-12 sm:text-center">The details.</p>

    <div class="flex flex-col justify-center items-center lg:flex-row pb-20">
      <div class="max-w-xl lg:w-1/2 md:mr-2 md:ml-4 pb-10 md:pb-0 z-10">
        <div class="w-full bg-gray-200 text-center p-6 rounded-t">
          <h3 class="text-gray-900 uppercase tracking-wide text-lg sm:text-xl text-center font-bold my-0">
            Pricing
          </h3>
        </div>
        <div class="w-full bg-white p-4 rounded-b">
          <div class="text-center py-6 lg:py-4">
            <span class="line-through opacity-75 inline-flex items-center font-display text-xl md:text-2xl font-semibold text-gray-600 mr-3 sm:mr-4">
              <span class="text-xl">$</span><span>349</span>
            </span>
            <span class="inline-flex items-center font-display text-5xl md:text-6xl font-bold text-black mr-3 sm:mr-4">
              <span class="text-2xl md:text-3xl">$</span><span>299</span>
            </span>
            <span class="inline-flex items-center font-display text-xl md:text-2xl font-bold text-grey-dark mr-3 sm:mr-4">
              USD
            </span>
            <div class="text-xs text-gray-700">3.5% credit card processing fee can either be built in or passed on.</div>
          </div>
          <ul class="list-reset px-2 sm:px-6 text-sm sm:text-sm md:text-base pb-6">
            <li class="flex items-center mb-4">
              <span class="mr-4"><svg class="h-5 w-5 block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><g fill="none" fill-rule="evenodd"><circle cx="10" cy="10" r="10" fill="#FBD38D"></circle><polyline stroke="rgb(246, 153, 63)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="6 10 8.667 12.667 14 7.333"></polyline></g></svg></span>
              <span>One event <strong>with up to 1000 individual entries.</strong></span>
            </li>
            <li class="flex items-center mb-4">
              <span class="mr-4"><svg class="h-5 w-5 block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><g fill="none" fill-rule="evenodd"><circle cx="10" cy="10" r="10" fill="#FBD38D"></circle><polyline stroke="rgb(246, 153, 63)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="6 10 8.667 12.667 14 7.333"></polyline></g></svg></span>
              <span>Set up and configure an <strong>unlimited number of individual shows </strong> for the event.</span>
            </li>
            <li class="flex items-center mb-4">
              <span class="mr-4"><svg class="h-5 w-5 block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><g fill="none" fill-rule="evenodd"><circle cx="10" cy="10" r="10" fill="#FBD38D"></circle><polyline stroke="rgb(246, 153, 63)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="6 10 8.667 12.667 14 7.333"></polyline></g></svg></span>
              <span><strong>Fully customizable</strong> show entry settings. Includes support for <strong>all variety of livestock</strong> and show types.</span>
            </li>
            <li class="flex items-center mb-4">
              <span class="mr-4"><svg class="h-5 w-5 block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><g fill="none" fill-rule="evenodd"><circle cx="10" cy="10" r="10" fill="#FBD38D"></circle><polyline stroke="rgb(246, 153, 63)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="6 10 8.667 12.667 14 7.333"></polyline></g></svg></span>
              <span>A <strong>complete set</strong> of show day tools that take you from check in to recording the final show results.</span>
            </li>
            <div class="hr"></div>
            <p class="text-gray-900 uppercase tracking-wide sm:text-lg text-center font-bold pb-4">
              Launch Exclusives
            </p>
            <li class="flex items-center mb-4">
              <span class="mr-4"><svg class="h-5 w-5 block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><g fill="none" fill-rule="evenodd"><circle cx="10" cy="10" r="10" fill="#FBD38D"></circle><polyline stroke="rgb(246, 153, 63)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="6 10 8.667 12.667 14 7.333"></polyline></g></svg></span>
              <span><strong>Personalized one-on-one onboarding.</strong> Ensure that your shows settings and presentation are exactly how you want them.</span>
            </li>
            <li class="flex items-center mb-4">
              <span class="mr-4"><svg class="h-5 w-5 block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><g fill="none" fill-rule="evenodd"><circle cx="10" cy="10" r="10" fill="#FBD38D"></circle><polyline stroke="rgb(246, 153, 63)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="6 10 8.667 12.667 14 7.333"></polyline></g></svg></span>
              <span><strong>Guaranteed Pricing.</strong> All new features developed between now and your show are included at no additional cost.</span>
            </li>
            <li class="flex items-center mb-4">
              <span class="mr-4"><svg class="h-5 w-5 block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><g fill="none" fill-rule="evenodd"><circle cx="10" cy="10" r="10" fill="#FBD38D"></circle><polyline stroke="rgb(246, 153, 63)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="6 10 8.667 12.667 14 7.333"></polyline></g></svg></span>
              <span><strong>Premium Support</strong> including phone and weekend support.</span>
            </li>
          </ul>
          <!--<router-link :to="'signup'" class="btn btn-orange w-full block text-center">Sign up</router-link>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebaseInit from '@/firebaseInit.js'
var db = firebaseInit.db

export default {
  data () {
    return {
      signUpObject: {
      /* name: '',
        email: '',
        phone_number: '',
        show_name: '',
        show_start_date: null,
        species: [] */
      },
      speciesList: [
        'Cattle',
        'Dairy Cattle',
        'Dairy Goats',
        'Goats',
        'Lambs',
        'Swine',
        'Other'
      ],
      interestLevels: [
        {
          label: 'I definitely want to use Showman!',
          value: 'SIGNUP'
        },
        {
          label: 'I have a few questions or I would like to see a demo.',
          value: 'DEMO'
        }
      ],
      refferalSources: [
        {
          label: 'I used Showman as an Exhibitor',
          value: 'EXHIBITOR'
        },
        {
          label: 'I was referred by someone else (Add their name below)',
          value: 'REFERRAL'
        },
        {
          label: 'I saw it in post or discussion on Facebook',
          value: 'FACEBOOK'
        },
        {
          label: 'I found it in a Google Search',
          value: 'GOOGLE'
        },
        {
          label: 'Other (You can add details below)',
          value: 'OTHER'
        }
      ],
      // error checking
      missingField: '',
      formStatus: 'none',
      errorMessage: '',
      missingTOS: false
    }
  },
  methods: {
    signUpClicked () {
      this.missingField = ''
      if (!this.signUpObject.first_name) {
        this.missingField = 'first name'
        return
      } else if (!this.signUpObject.last_name) {
        this.missingField = 'last name'
        return
      } else if (!this.signUpObject.email) {
        this.missingField = 'email'
        return
      } else if (!this.signUpObject.phone_number) {
        this.missingField = 'phone number'
        return
      } else if (!this.signUpObject.interest) {
        this.missingField = 'interest'
        return
      } else if (!this.signUpObject.how_did_you_hear) {
        this.missingField = 'how_did_you_hear'
        return
      } else if (!this.signUpObject.show_name) {
        this.missingField = 'show name'
        return
      } else if (!this.signUpObject.show_start_date) {
        this.missingField = 'show start date'
        return
      } else if (!this.signUpObject.online_entires_open) {
        this.missingField = 'entry open date'
        return
      } else if (!this.signUpObject.species) {
        this.missingField = 'species'
        return
      } else if (!this.signUpObject.show_state) {
        this.missingField = 'show_state'
        return
      } else if (!this.signUpObject.tos) {
        this.missingTOS = true
        return
      }
      this.missingField = ''
      this.formStatus = 'loading'
      this.signUpObject.signup_date = new Date()
      this.signUpObject.status = 'Fresh'

      // Firebase
      db.collection('signups').add(this.signUpObject)
        .then(ref => {
          // console.log(ref)
          // Mailchimp
          /* this.axios.post(`${process.env.VUE_APP_ROOT_API}/showman/add-subscriber-giveaway?email=${this.signUpObject.email}&first=${this.signUpObject.name}`)
            .then(() => {
              this.formStatus = 'success'
            })
            .catch(error => {
              this.formStatus = 'error'
              alert(error)
              if (error.response.data.error.title === 'Member Exists') {
                this.errorMessage = `It looks like you have already signed up using this email. If you would like to update your information or have any questions you can send an email to ${process.env.VUE_APP_SUPPORT_EMAIL}`
              } else if (error.response.data.error.title === 'Invalid Resource') {
                this.errorMessage = `Looks like there may be an issue with your email address. Double check it to make sure there weren't any mistakes and try again.`
              } else {
                this.errorMessage = `Well this is embarrasing, I'm not sure what is wrong. You can refresh your browser and try again. If that doesn't work please send an email to ${process.env.VUE_APP_SUPPORT_EMAIL} for help.`
              }
              // this.$honeybadger.notify(error)
            })
          */
          // User List
          this.axios.post(`${process.env.VUE_APP_ROOT_API}/showman/add-user-list`, this.signUpObject)
            .then(ref => {
              // console.log(ref)
              this.formStatus = 'success'
            })
            .catch(() => {
              // console.log(error)
              this.formStatus = 'error'
            })
          this.signUpObject = {}
        }).catch(err => {
          this.errorMessage = err
          this.formStatus = 'error'
        })
    }
  }
}
</script>

<style>

</style>
